import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { gsap } from 'gsap'
import utils from "../components/utils";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import CircleLink from "../components/circle-link";
import Img from "gatsby-image"
import ContactForm from "../components/contact-form";
import SEO from "../components/seo";
import { options } from "../cms-helpers";

export default function BlogPost({ data, entry }) {

  useEffect(() => {
    utils.resetPage()
    gsap.to('body', {
      opacity: 1,
      duration: 0.5,
      ease: "none"
    })
    utils.fadeNav(false)
    gsap.to('#nav', {
      opacity: 1,
      duration: 0.7,
      ease: "power2.out"
    })
    gsap.to('.blog-post-content', {
      opacity: 1,
      duration: 0.7,
      delay: 0.5,
      ease: "power2.out"
    })

    gsap.to('.post-date', {
      opacity: 1,
      duration: 0.7,
      delay: 1,
      ease: "power2.out"
    })
  }, [])

  const blogPost = data.contentfulBlogPost;
  const isLeft = entry && entry.state && entry.state.side && entry.state.side === 'left'
  const related = data.allContentfulBlogPost.nodes

  return (
    <Layout>
      <SEO
        title={blogPost.meta.title}
        description={blogPost.meta.description}
        image={blogPost.meta.image.fluid.src}
      />
      {
        isLeft ? <section className="blog-post lg:grid lg:grid-cols-2 gap-0">
          <div data-scroll data-scroll-sticky data-scroll-target=".blog-post" className={`h-50vh h-mobile-landscape-150vh  lg:min-h-screen`}>
            <BackgroundImage backgroundColor={utils.getPurpleHex()} fluid={blogPost.sideImage.fluid} className={`w-full h-50vh h-mobile-landscape-150vh  lg:min-h-screen blog-post__image `} />
          </div>
          <div className={`relative`}>
            <div className="lg:min-h-screen flex flex-col px-4 lg:px-12 p-12 justify-end relative">
              {/* <div className="post-date lg:absolute lg:top-48 font-mabry uppercase text-sm" style={{
                opacity: 0
              }}>
                {blogPost.publishedDate}
              </div> */}
              <div className="lg:max-w-lg lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 pt-8 lg:pt-0">
                <h3 className="font-sailec text-lg mb-2 lg:mb-8">{blogPost.title}</h3>
                <p className="font-sailec">Published by {blogPost.author.firstName} {blogPost.author.lastName}</p>
              </div>
            </div>
            <section className={`blog-post-content lg:transform lg:-translate-y-44`} style={{
              opacity: 0
            }}>
              <div className="px-4 lg:px-12 p-12 body-content">
                <div className="max-w-lg">
                  {renderRichText(blogPost.content, options)}
                  <div className="mt-8">
                    <CircleLink title="Go Back" isLink={true} to="/articles" onPress={() => {
                      utils.fadeOutFromPurple()
                    }} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section> :
          <section className="blog-post lg:grid lg:grid-cols-2 gap-0">
            <div data-scroll data-scroll-sticky data-scroll-target=".blog-post" className={`block lg:hidden h-50vh h-mobile-landscape-150vh  lg:min-h-screen`}>
              <BackgroundImage backgroundColor={utils.getPurpleHex()} fluid={blogPost.sideImage.fluid} className={`w-full h-50vh h-mobile-landscape-150vh  lg:min-h-screen blog-post__image `} />
            </div>
            <div className={`relative`}>
              <div className="lg:min-h-screen flex flex-col px-4 lg:px-12 p-12 justify-end relative">
                {/* <div className="lg:absolute lg:top-48 font-mabry uppercase text-sm">
                  {blogPost.publishedDate}
                </div> */}
                <div className="lg:max-w-lg lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 pt-8 lg:pt-0">
                  <h3 className="font-sailec text-lg mb-2 lg:mb-8">{blogPost.title}</h3>
                  <p className="font-sailec">Published by {blogPost.author.firstName} {blogPost.author.lastName}</p>
                </div>
              </div>
              <section className={`blog-post-content lg:transform lg:-translate-y-44`} style={{
                opacity: 0
              }}>
                <div className="px-4 lg:px-12 p-12 body-content">
                  <div className="max-w-lg">
                    {renderRichText(blogPost.content, options)}
                    <div className="mt-8">
                      <CircleLink title="Go Back" isLink={true} to="/articles" onPress={() => {
                        utils.fadeOutFromPurple()
                      }} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div data-scroll data-scroll-sticky data-scroll-target=".blog-post" className={`hidden lg:block h-50vh lg:min-h-screen`}>
              <BackgroundImage backgroundColor={utils.getPurpleHex()} fluid={blogPost.sideImage.fluid} className={`w-full h-50vh lg:min-h-screen blog-post__image `} />
            </div>
          </section>
      }
      <div className="container">
        <div className="px-0 lg:px-12 p-12 my-24">
          <h2 className="font-sc text-lg lg:text-xl text-center">Related</h2>
          <div className="lg:grid lg:grid-cols-3 lg:gap-12 mt-8 lg:mt-32">
            {related.map((article, index) => <article className={`mt-20 lg:mt-0 ${index === 2 ? 'hidden lg:block' : ''}`}>
              <Img fluid={{ ...article.sideImage.fluid, aspectRatio: 1 }} className="mb-12" />
              <h3 className="font-sailec text-lg mb-2">{article.title}</h3>
              <p className="mb-4">Published by {article.author.firstName} {article.author.lastName}</p>
              <CircleLink title="Read Article" isLink={true} to={`/article/${article.slug}`} onPress={() => {
                utils.fadeOutFromPurple()
              }} />
            </article>)}
          </div>
        </div>
      </div>
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      publishedDate(formatString: "DD-MM-YYYY")
      slug
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
      title
      content {
        raw
      }
      sideImage { 
        fluid(quality: 90, maxHeight: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
          src
        }
      }
      author {
        firstName
        lastName
      }
    }
    allContentfulBlogPost(filter: {slug: {ne: $slug}}, limit: 3) {
      nodes {
        slug
        title
        content {
          raw
        }
        sideImage { 
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        author {
          firstName
          lastName
        }
      }
    }
  }
`
